import { BleManager } from 'react-native-ble-plx';
import {Platform, Vibration} from "react-native";
import { Buffer } from 'buffer';

export default class BluetoothSerial {
    constructor() {
        this.manager = new BleManager();
        this.device = null;
        this.serviceUUID = null;
        this.characteristicUUID = null;

        if (Platform.OS === 'android' && Platform.Version >= 23) {
            const {PermissionsAndroid} = "react-native";

            PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION).then((result) => {
                if (!result) {
                    Vibration.vibrate([100 ,100]);
                    console.error('User refused location permission');
                }
            });
        }
    }

    // Scan for devices
    async scanForDevices() {
        return new Promise((resolve, reject) => {
            this.manager.startDeviceScan(null, null, (error, device) => {
                if (error) {
                    reject(error);
                    return;
                }

                if (device.name.toLowerCase().includes("ecr-y04700600")) {
                    this.manager.stopDeviceScan();
                    this.device = device;
                    resolve(device);
                }
            });
        });
    }

    // Connect and discover services and characteristics
    async connectAndDiscoverServices() {
        if (!this.device) throw new Error("No device found to connect to");

        await this.device.connect();
        await this.device.discoverAllServicesAndCharacteristics();

        // Discover services and characteristics and assign them
        const services = await this.device.services();
        for (const service of services) {
            const characteristics = await service.characteristics();
            for (const characteristic of characteristics) {
                // Use a known characteristic if you have it, or assign the first available
                this.serviceUUID = service.uuid;
                this.characteristicUUID = characteristic.uuid;
                alert(`Service UUID: ${service.uuid}, Characteristic UUID: ${characteristic.uuid}`);

                // Break after finding the first writable characteristic
                if (characteristic.isWritableWithResponse || characteristic.isWritableWithoutResponse) {
                    break;
                }
            }
            if (this.characteristicUUID) break; // Break if we found a characteristic
        }
    }

    // Send data over Bluetooth Serial
    async sendData(data) {
        if (!this.device || !this.serviceUUID || !this.characteristicUUID) {
            throw new Error("Device not connected or service/characteristic UUIDs not found");
        }

        const buffer = Buffer.from(data, 'utf-8'); // Convert data to buffer for binary format
        const base64Data = buffer.toString('base64'); // Convert to base64

        await this.device.writeCharacteristicWithResponseForService(
            this.serviceUUID,
            this.characteristicUUID,
            base64Data
        );
        alert("Data sent:", data);
    }

    // Disconnect from the device
    async disconnect() {
        if (this.device) {
            await this.device.cancelConnection();
            this.device = null;
            this.serviceUUID = null;
            this.characteristicUUID = null;
        }
    }
}
