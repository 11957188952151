import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as TaskManager from 'expo-task-manager';
import * as BackgroundFetch from 'expo-background-fetch';
import { getAccessToken } from './storage';
import { Platform } from 'react-native';

const BACKGROUND_SYNC_TASK = 'background-sync-task';
const FETCH_INTERVAL = 15 * 60 * 1000; // 15 minutes in milliseconds
const RESTAURANT_DATA_KEY = 'restaurantData';

export const SystemContext = createContext();

export const AndroidSystemProvider = ({ children }) => {
    const [isRestaurantOpen, setIsRestaurantOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [restaurantData, setRestaurantData] = useState(null);


    useEffect(() => {
        const loadInitialData = async () => {
            const storedOrders = await AsyncStorage.getItem('orders');
            const storedRestaurantData = await AsyncStorage.getItem(RESTAURANT_DATA_KEY);
            if (storedOrders) {
                setOrders(JSON.parse(storedOrders));
            }
            if (storedRestaurantData) {
                setRestaurantData(JSON.parse(storedRestaurantData));
            } else {
                fetchRestaurantData();
            }
        };
        loadInitialData();
    }, []);

    const fetchOrders = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch('https://api.example.com/orders', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setOrders(data);
            await AsyncStorage.setItem('orders', JSON.stringify(data));
        } catch (error) {
            console.error('Failed to fetch orders:', error);
        }
    };

    const fetchRestaurantData = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch('https://api.example.com/restaurant', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setRestaurantData(data);
            await AsyncStorage.setItem(RESTAURANT_DATA_KEY, JSON.stringify(data));
        } catch (error) {
            console.error('Failed to fetch restaurant data:', error);
        }
    };

    useEffect(() => {
        if (Platform.OS !== 'web') {
            const intervalId = setInterval(fetchOrders, FETCH_INTERVAL);
            return () => clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        if (Platform.OS !== 'web') {
            const initBackgroundFetch = async () => {
                try {
                    await BackgroundFetch.registerTaskAsync(BACKGROUND_SYNC_TASK, {
                        minimumInterval: 15, // Fetch interval in minutes
                        stopOnTerminate: false,
                        startOnBoot: true,
                    });
                    console.log('Background fetch task registered');
                } catch (error) {
                    console.error('Background fetch task registration error:', error);
                }
            };

            TaskManager.defineTask(BACKGROUND_SYNC_TASK, async () => {
                try {
                    console.log('Background sync task running');
                    await fetchOrders();
                    return true;
                } catch (error) {
                    console.error('Background sync task error:', error);
                    return false;
                }
            });

            initBackgroundFetch();
        }
    }, []);

    return (
        <SystemContext.Provider value={{ isRestaurantOpen, setIsRestaurantOpen, orders, restaurantData, fetchOrders, fetchRestaurantData }}>
            {children}
        </SystemContext.Provider>
    );
};