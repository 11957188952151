import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const ResultScreen = ({ success }) => {
    return (
        <View style={[styles.container, { backgroundColor: success ? 'green' : 'red' }]}>
            <Text style={styles.message}>
                {success ? 'Vásárlás sikeres!' : 'Vásárlás sikertelen!'}
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        fontSize: 24,
        color: 'white',
        fontWeight: 'bold',
    },
});

export default ResultScreen;
