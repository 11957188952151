import React, { useState, useEffect } from 'react';
import {
    SafeAreaView,
    StyleSheet,
    View,
    Text,
    StatusBar,
    FlatList,
    TouchableOpacity,
    Platform, Switch,
    Modal,
    ActivityIndicator, Vibration
} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { LinearGradient } from 'expo-linear-gradient';
import BleManager from 'react-native-ble-manager';
import { NativeModules, NativeEventEmitter } from 'react-native';

const BleManagerModule = NativeModules.BleManager;
const bleManagerEmitter = new NativeEventEmitter(BleManagerModule);

const ScanDevicesScreen = ({ navigation }) => {
    const [isScanning, setIsScanning] = useState(false);
    const [devices, setDevices] = useState([]);
    const [isConnecting, setIsConnecting] = useState(false);

    useEffect(() => {
        if(Platform.OS !== 'web') {
            BleManager.start({showAlert: false});

            const handleDiscoverPeripheral = (peripheral) => {
                if (!peripheral.name) {
                    peripheral.name = 'NÉVTELEN';
                }
                setDevices((prevDevices) => [...prevDevices, peripheral]);
            };

            const handleStopScan = () => {
                setIsScanning(false);
                Vibration.vibrate([100 , 300]);
            };

            const listeners = [
                bleManagerEmitter.addListener('BleManagerDiscoverPeripheral', handleDiscoverPeripheral),
                bleManagerEmitter.addListener('BleManagerStopScan', handleStopScan),
            ];

            if (Platform.OS === 'android' && Platform.Version >= 23) {
                const {PermissionsAndroid} = "react-native";
                PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION).then((result) => {
                    if (!result) {
                        Vibration.vibrate([100 ,100]);
                        console.error('User refused location permission');
                    }
                });
            }
        }

    }, []);

    const startScan = async () => {
        if (!isScanning) {
            setDevices([]);
            setIsScanning(true);

            if (Platform.OS === 'web') {
                try {
                    if (!navigator.bluetooth) {
                        console.error('Web Bluetooth API is not available in this browser.');
                        setIsScanning(false);
                        return;
                    }

                    const options = {
                        acceptAllDevices: true,
                        optionalServices: ['battery_service']
                    };

                    const device = await navigator.bluetooth.requestDevice(options);
                    const deviceInfo = {
                        id: device.id,
                        name: device.name || 'NÉVTELEN'
                    };

                    setDevices((prevDevices) => [...prevDevices, deviceInfo]);
                    setIsScanning(false);
                    Vibration.vibrate([100 , 300]);

                } catch (error) {
                    console.error('Error scanning for Bluetooth devices:', error);
                    setIsScanning(false);
                    Vibration.vibrate(300);
                }
            } else {
                BleManager.scan([], 10, true).catch((err) => {
                    console.error(err);
                    setIsScanning(false);
                    Vibration.vibrate(300);
                });
            }
        } else {
            BleManager.stopScan().catch((err) => {
                console.error(err);
                Vibration.vibrate(300);
            });
        }
    };

    React.useEffect(() => {
        if(!isScanning) startScan();
    }, []);

    const getDeviceIcon = (device) => {
        if (device.advertising && device.advertising.serviceUUIDs) {
            if (device.advertising.serviceUUIDs.includes('180D')) {
                return 'heart'; // Heart Rate Monitor
            } else if (device.advertising.serviceUUIDs.includes('180F')) {
                return 'battery'; // Battery Service
            } else if (device.advertising.serviceUUIDs.includes('180A')) {
                return 'info'; // Device Information
            } else if (device.advertising.serviceUUIDs.includes('1812')) {
                return 'gamepad'; // Human Interface Device
            } else if (device.advertising.serviceUUIDs.includes('1802')) {
                return 'alert-circle'; // Immediate Alert
            } else if (device.advertising.serviceUUIDs.includes('1803')) {
                return 'bell'; // Link Loss
            } else if (device.advertising.serviceUUIDs.includes('1804')) {
                return 'volume-2'; // Tx Power
            } else if (device.advertising.serviceUUIDs.includes('1805')) {
                return 'clock'; // Current Time Service
            } else if (device.advertising.serviceUUIDs.includes('1806')) {
                return 'thermometer'; // Temperature
            } else if (device.advertising.serviceUUIDs.includes('181A')) {
                return 'activity'; // Environmental Sensing
            } else if (device.advertising.serviceUUIDs.includes('181B')) {
                return 'bar-chart'; // Body Composition
            } else if (device.advertising.serviceUUIDs.includes('181C')) {
                return 'sun'; // User Data
            } else if (device.advertising.serviceUUIDs.includes('181D')) {
                return 'activity'; // Weight Scale
            } else if (device.advertising.serviceUUIDs.includes('181E')) {
                return 'watch'; // Bond Management
            } else if (device.advertising.serviceUUIDs.includes('181F')) {
                return 'map'; // Continuous Glucose Monitoring
            } else if (device.advertising.serviceUUIDs.includes('1820')) {
                return 'thermometer'; // Internet of Things (IoT)
            } else if (device.advertising.serviceUUIDs.includes('1821')) {
                return 'wifi'; // Indoor Positioning
            } else if (device.advertising.serviceUUIDs.includes('1822')) {
                return 'shield'; // Health Thermometer
            } else if (device.advertising.serviceUUIDs.includes('1823')) {
                return 'activity'; // Pulse Oximeter
            } else if (device.advertising.serviceUUIDs.includes('1824')) {
                return 'watch'; // Weight Scale
            } else if (device.advertising.serviceUUIDs.includes('1825')) {
                return 'map-pin'; // Location and Navigation
            } else if (device.advertising.serviceUUIDs.includes('1826')) {
                return 'thermometer'; // Environmental Sensing
            } else if (device.advertising.serviceUUIDs.includes('1827')) {
                return 'activity'; // Body Composition
            } else if (device.advertising.serviceUUIDs.includes('1828')) {
                return 'sun'; // User Data
            } else if (device.advertising.serviceUUIDs.includes('1829')) {
                return 'watch'; // Bond Management
            } else if (device.advertising.serviceUUIDs.includes('1830')) {
                return 'map'; // Continuous Glucose Monitoring
            }
        }
        if (device.name.includes('TV') || device.name.includes('Television')) {
            return 'monitor';
        } else if (device.name.includes('Headphone')) {
            return 'headphones';
        } else if (device.name.includes('net')) {
            return 'radio';
        } else if (device.name.includes('Watch')) {
            return 'watch';
        } else if (device.name.includes('Speaker')) {
            return 'speaker';
        } else if (device.name.includes('Keyboard')) {
            return 'keyboard';
        } else if (device.name.includes('Mouse')) {
            return 'mouse-pointer';
        } else if (device.name.includes('Phone')) {
            return 'phone';
        } else if (device.name.includes('Tablet')) {
            return 'tablet';
        } else if (device.name.includes('Laptop')) {
            return 'laptop';
        } else if (device.advertising && device.advertising.isConnectable) {
            return 'link';
        } else if (device.advertising && device.advertising.txPowerLevel) {
            return 'signal';
        } else {
            return 'bluetooth';
        }
    };

    React.useEffect(() => {
        if (!isScanning) startScan();
    }, []);

    const renderItem = ({ item }) => (
        <TouchableOpacity style={styles.deviceContainer} onPress={() => {
            setIsConnecting(true);
            setTimeout(() => {
                setIsConnecting(false);
                alert(`Sikertelen csatlakozás az eszközhöz!`);
            }, 3000);
        }}>
            <FeatherIcon name={getDeviceIcon(item)} size={24} color="#000" style={styles.deviceIcon} />
            <Text style={styles.deviceName}>{item.name}</Text>
            <Text style={styles.deviceId}>{item.id}</Text>
        </TouchableOpacity>
    );

    // '56:2F:58:05:09:30:2A:06'
    // '18:04:16:3C:20:4D:4D:37'


    const [filterUnnamed, setFilterUnnamed] = useState(true);

    return (
        <LinearGradient colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']} style={{ flex: 1, width: '100%', height: '100%' }}>
            <SafeAreaView style={{ flex: 1, backgroundColor: 'transparent', paddingTop: Platform.OS === 'web' ? 10 : 50 }}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
                <View style={styles.content}>
                    <TouchableOpacity style={styles.scanButton} onPress={startScan}>
                        <Text style={styles.scanButtonText}>{isScanning ? 'Keresés leállítása...' : 'Eszközök keresése'}</Text>
                    </TouchableOpacity>
                    <View style={styles.switchContainer}>
                        <Text style={styles.switchLabel}>Csak a névvel rendelkező eszközök megjelenítése</Text>
                        <Switch
                            vibrate={true}
                            trackColor={{ false: "#767577", true: "#f1b34b" }}
                            ios_backgroundColor="#3e3e3e"
                            thumbColor="#f1b34b"                            value={filterUnnamed}
                            onValueChange={setFilterUnnamed}
                        />
                    </View>
                    <FlatList
                        data={filterUnnamed ? devices.filter((d) => d.name !== "NÉVTELEN") : devices}
                        keyExtractor={(item) => item.id}
                        renderItem={renderItem}
                        contentContainerStyle={[styles.deviceList, { paddingBottom: 60 }]} // Add padding to the bottom
                    />
                </View>
            </SafeAreaView>

            <Modal
                transparent={true}
                animationType="fade"
                visible={isConnecting}
                onRequestClose={() => setIsConnecting(false)}
            >
                <View style={styles.modalBackground}>
                    <View style={styles.modalContainer}>
                        <ActivityIndicator size="large" color="#f1b34b" />
                        <Text style={styles.modalText}>Csatlakozás...</Text>
                    </View>
                </View>
            </Modal>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingHorizontal: 16,
    },
    headerAction: {
        width: 40,
        height: 40,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    headerTitle: {
        fontSize: 19,
        fontWeight: '600',
        color: '#000',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        textAlign: 'center',
    },
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent',
    },
    scanButton: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 16,
        paddingHorizontal: 16,
        backgroundColor: '#f2b34c',
        margin: 10,
        borderRadius: 12,
    },
    scanButtonText: {
        fontSize: 16,
        letterSpacing: 0.25,
        color: '#fff',
    },
    deviceList: {
        paddingVertical: 16,
    },
    deviceContainer: {
        padding: 12,
        backgroundColor: '#fff',
        borderRadius: 12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    deviceName: {
        fontSize: 16,
        fontWeight: '600',
        color: '#292929',
    },
    deviceId: {
        fontSize: 14,
        color: '#858585',
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        marginVertical: 10,
    },
    switchLabel: {
        fontSize: 16,
        color: '#000',
    },
    deviceIcon: {
        marginRight: 10,
    },
    modalBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        width: 200,
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 15,
        alignItems: 'center',
    },
    modalText: {
        marginTop: 10,
        fontSize: 16,
        color: '#333',
    },
});

export default ScanDevicesScreen;