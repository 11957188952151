// HomeScreen.js
import React, {useEffect} from 'react';

import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {Alert, Linking, StyleSheet} from "react-native";
import Icon from 'react-native-vector-icons/Feather';
import SettingsScreen from "./SettingsScreen";
import * as Notifications from 'expo-notifications';
import {registerForPushNotificationsAsync} from "../helpers/fcmNotifications";
import SaleScreen from "./SaleScreen";
import CartScreen from "./CartScreen";
import { CartContext } from '../helpers/CartContext';
const Tab = createBottomTabNavigator();


export default function HomeScreen({navigation}) {
    const { cartItems = [] } = React.useContext(CartContext);

    React.useEffect(() => {
        Notifications.setNotificationHandler({
            handleNotification: async () => ({
                shouldShowAlert: true,
                shouldPlaySound: true,
                shouldSetBadge: false,
            }),
        });

        Notifications.addNotificationReceivedListener(notification => {
            console.log('Notification received:', notification);
            Alert.alert("Új értesítés: " + notification.request.content.title, notification.request.content.body);
        });

        Notifications.addNotificationResponseReceivedListener(response => {
            console.log('Notification response:', response);
            Alert.alert("Értesítés vissza: " + response.notification.request.content.title, response.notification.request.content.body);

        });

        registerForPushNotificationsAsync().then(token => console.log(token));
    }, []);

    return (<Tab.Navigator
            detachInactiveScreens={false}
            tabBarLabelPosition={"below-icon"}
            lazy={false}
            backBehavior={"history"}
            headerMode={"none"}
            screenOptions={{headerShown: false}}
            tabBarOptions={{
                activeTintColor: '#a26909',
                inactiveTintColor: '#575757',
                activeBackgroundColor: '#f0f0f0',
                inactiveBackgroundColor: '#ffffff',
                showLabel: true,
                labelStyle: {
                    fontSize: 14,
                    fontWeight: 'bold',
                },
                tabStyle: {
                    paddingVertical: 10,
                    height: 70,
                    marginTop: -20
                },
                style: {
                    height: 70,
                    backgroundColor: '#e0e0e0',
                }
            }}
        >
            <Tab.Screen name="Sale" component={SaleScreen}
                        options={{
                            tabBarIcon: ({color, size}) => (
                                <Icon name="check-square" color={color} size={size}/>
                            ),
                            title: 'Értékesítés',
                            tabBarLabel: 'Értékesítés',
                        }}/>
            <Tab.Screen name="Cart" component={CartScreen}
                        options={{
                            tabBarIcon: ({color, size}) => (
                                <Icon name="shopping-cart" color={color} size={size}/>
                            ),
                            title: 'Kosárban lévő termékek',
                            tabBarLabel: 'Kosár',
                            ...((cartItems.length > 0) && {tabBarBadge: cartItems.length || 0}),
                        }}/>
            <Tab.Screen name="Settings" component={SettingsScreen}
                        options={{
                            tabBarIcon: ({color, size}) => (
                                <Icon name="user" color={color} size={size}/>
                            ),
                            title: "Fiókom és beállítás",
                            tabBarLabel: 'Beállítások',
                        }}/>
        </Tab.Navigator>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
