// CartScreen.js
import React, { useState } from 'react';
import {View, Text, FlatList, Button, StyleSheet, TouchableOpacity, Dimensions, Platform} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import LottieView from "lottie-react-native";
import emptyCartNotification from "../../assets/animations/empty_cart.json";
import {LinearGradient} from "expo-linear-gradient";
import Spinner from "react-native-loading-spinner-overlay";
import {CartContext} from "../helpers/CartContext";
import {priceFormatter} from "../helpers/numbers";
const {width, height} = Dimensions.get('window');


const exampleCartItems = [
    { id: '1', name: 'Felnőtt napijegy', price: 3499, quantity: 2 },
    { id: '2', name: 'VIP jegy', price: 9990, quantity: 1 },
    { id: '3', name: 'VIP jegy', price: 9990, quantity: 1 },
]

const CartScreen = ({navigation}) => {
    const { cartItems, addToCart, removeFromCart, setCart } = React.useContext(CartContext);

    const incrementQuantity = (id) => {
        setCart(cartItems.map(item =>
            item._id === id ? { ...item, quantity: item.quantity + 1 } : item
        ));
    };

    const decrementQuantity = (id) => {
        if(cartItems.find(item => item._id === id) && cartItems.find(item => item._id === id).quantity <= 1) {
            return removeFromCart(id);
        }
        setCart(cartItems.map(item =>
            item._id === id && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
        ));
    };

    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };
    const renderItem = ({ item }) => (
        <View style={styles.itemContainer}>
            <View style={{flex: 4}}>
                <Text style={styles.itemName}>{item.name}</Text>
                <Text style={styles.unitPrice}>1 db / {priceFormatter(item.price)} Ft</Text>
            </View>
            <View style={[styles.quantityContainer, {flex: 2, textAlign: 'center', alignItems: 'center'}]}>
                <TouchableOpacity onPress={() => decrementQuantity(item._id)}>
                    <Icon name="minus" size={20} />
                </TouchableOpacity>
                <Text style={styles.quantityText}>{item.quantity}</Text>
                <TouchableOpacity onPress={() => incrementQuantity(item._id)}>
                    <Icon name="plus" size={20} />
                </TouchableOpacity>
            </View>
            <Text style={[styles.itemPrice, {flex: 2, alignItems: "flex-end", textAlign: 'right'}]}>{priceFormatter(item.price * item.quantity)}</Text>
        </View>
    );

    return (
        <LinearGradient
            // Replace colors with the ones matching the image you provided
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}  // Example gradient colors
            style={{flex: 1, width: '100%', height: '100%'}}
        ><View style={styles.container}>
            {cartItems.length > 0 && <FlatList
                data={cartItems}
                renderItem={renderItem}
                keyExtractor={item => item.id}
            />}
            {cartItems.length === 0 && <View style={{
                flex: 1,
                alignContent: 'center',
                alignItems: 'center'
            }}>
                <View style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 120
                }}>
                    <LottieView
                        source={emptyCartNotification}
                        autoPlay
                        loop={true}
                        style={styles.lottie}
                        renderMode={'AUTOMATIC'}
                        resizeMode={'contain'}
                        enableSafeModeAndroid={true}
                    />
                    <Text style={{fontSize: 22, color: 'black', marginTop: 20}}>
                        Nincs egyetlen tétel sem itt!
                    </Text>
                </View>
            </View>}
            <View style={[styles.totalContainer, {flexDirection: 'row'}]}>
                <Text style={styles.totalText}>Fizetendő:</Text><Text style={styles.totalText}>{priceFormatter(calculateTotal())}</Text>
            </View>
            <View style={[styles.totalContainer, {fontSize: 40, padding: 5}]}>
                <TouchableOpacity style={styles.processButton} disabled={cartItems.length === 0 } onPress={() => {
                    if(cartItems.length === 0) return;
                    navigation.navigate("PaymentScreen");
                }}>
                    <Text style={styles.processButtonText}>Tovább a fizetéshez</Text>
                </TouchableOpacity>
            </View>
        </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        paddingTop: 10
    },
    processButton: {
        backgroundColor: 'orange',
        alignItems: 'center',
        padding: 15,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5
    },
    processButtonText: {
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },
    unitPrice: {
        fontSize: 12,
        color: '#888',
    },
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    itemName: {
        fontSize: 16,
    },
    quantityContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    quantityText: {
        marginHorizontal: 8,
        fontSize: 16,
        fontWeight: 700
    },
    itemPrice: {
        fontSize: 16,
    },
    totalContainer: {
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: '#ccc',
        justifyContent: 'space-between'
    },
    totalText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    animationContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    lottie: {
        width: 600,
        height: 600
    },
    background: {
        flex: 1,
        background: '#393939',
        width: '100%',
        height: height - 90
    },
    spinnerTextStyle: {
        color: '#FFF'
    },
});

export default CartScreen;