import { Alert, Linking } from 'react-native';

// Configuration constants (customize as needed)
const MERCHANT_KEY = '53f16839-d68e-4c24-b3d3-0f8aff2d7751';
const APP_ID = 'hu.happysolutions.services';
const CALLBACK_SCHEME = 'happy-services';

const SOURCE_TERMINAL_ID = '16009276';
const API_KEY = 'i1mj2tpgwnx9r1x95yph06c20b80fiskj09dh0mv4y801.apps.vivapayments.com';
const API_SECRET = 'h68016kM1zticDubrTUb36vsjSDTiV';
const PIN_CODE = '111111';

const ISV_CLIENT_ID = 'kf7fpz4c4gkc6ch03u4415o8acipq9xdefzuto4b6by94.apps.vivapayments.com';
const ISV_CLIENT_SECRET = 'SY5Nt33019xdyagX85Ct6DQwpTiZhG';
const ISV_SOURCE_CODE = 'Default';
const ISV_CURRENCY_CODE = 978;
const ISV_CUSTOMER_TRNS = 'ItemDescription';
const ISV_CLIENT_TRANSACTION_ID = '12345678901234567890123456789012';
const ISV_MERCHANT_ID = '1234567890';
const ISV_MERCHANT_SOURCE_CODE = 'Default';

const VivaPaymentsModule = {
    async initiatePayment(amount, tipAmount = 0) {
        const CLIENT_TRANSACTION_ID = Date.now().toString(); // Generate unique transaction ID

        const paymentUrl = `vivapayclient://pay/v1`
            + `?appId=${APP_ID}`
            + `&action=sale`
            + `&clientTransactionId=${CLIENT_TRANSACTION_ID}`
            + `&amount=${amount}`
            + `&tipAmount=${tipAmount}`
            + `&show_receipt=true`
            + `&show_transaction_result=true`
            + `&show_rating=true`
            //+ `&ISV_amount=100`
            //+ `&ISV_clientId=${ISV_CLIENT_ID}`
            //+ `&ISV_clientSecret=${ISV_CLIENT_SECRET}`
            //+ `&ISV_sourceCode=${ISV_SOURCE_CODE}`
            //+ `&ISV_currencyCode=${ISV_CURRENCY_CODE}`
            //+ `&ISV_customerTrns=${ISV_CUSTOMER_TRNS}`
            //+ `&ISV_clientTransactionId=${ISV_CLIENT_TRANSACTION_ID}`
            //+ `&ISV_merchantId=${ISV_MERCHANT_ID}`
            //+ `&ISV_merchantSourceCode=${ISV_MERCHANT_SOURCE_CODE}`
            + `&paymentMethod=CardPresent`
            + `&callback=${CALLBACK_SCHEME}://payment/result`;


        console.log("paymentUrl: " + paymentUrl);

        // Attempt to open the URL
        const supported = await Linking.canOpenURL(paymentUrl);
        if (supported) {
            await Linking.openURL(paymentUrl);
        } else {
            Alert.alert('Váratlan hiba történt', 'Nem sikerült a Viva alkalmazás megnyitása, kérjük próbálja meg kézzel fizettetni!\n\nHibaüzenet: ' + JSON.stringify(supported));
        }
    },

    // Call this function in the main app to handle callback URL
    handleCallbackUrl(url, navigation) {
        const resultUri = new URL(url);

        const status = resultUri.searchParams.get('status')
        const message = resultUri.searchParams.get('message')
        const amount = resultUri.searchParams.get('amount')
        const cardType = resultUri.searchParams.get('cardType')
        const accountNumber = resultUri.searchParams.get('accountNumber')

        //Alert.alert('Tranzakció státusza: ' + status, message || 'No details received.');
        return { url, responseData: {status, message, amount, cardType, accountNumber} };
    }
};

export default VivaPaymentsModule;