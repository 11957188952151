// NotificationsScreen.js
import React from "react";
import {Dimensions, RefreshControl, ScrollView, StatusBar, StyleSheet, Text, View} from "react-native";
import {getAccessToken} from "../helpers/storage";
import noNotificationAnimation from '../../assets/animations/no-notification.json';
import LottieView from "lottie-react-native";

const {width, height} = Dimensions.get('window');

export default function NotificationsScreen({navigation}) {
    const [localStorage, setLocalStorage] = React.useState(null);
    const [refreshing, setRefreshing] = React.useState(false);

    React.useEffect(() => {
        const checkLogin = async () => {
            if (await getAccessToken()) {
                setLocalStorage(await getAccessToken())
                //navigation.navigate("HomeScreen");
            }
        }
        checkLogin();
    }, [navigation]);


    const onRefresh = async () => {
        setRefreshing(true);
        // Add your refresh logic here
        setTimeout(() => {
            setRefreshing(false);
        }, 2500)
    };


    return (
        <View
            style={styles.background}
        >
            <StatusBar backgroundColor="transparent" translucent barStyle="dark-content"/>
            <ScrollView refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
            } style={styles.container}>
                <View style={{
                    flex: 1,
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <View style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 60
                    }}>
                        <LottieView
                            source={noNotificationAnimation}
                            autoPlay
                            loop={true}
                            style={styles.lottie}
                            renderMode={'AUTOMATIC'}
                            resizeMode={'center'}
                            enableSafeModeAndroid={true}
                        />
                        <Text style={{fontSize: 22, color: 'yellow', marginTop: -80}}>Nincs egyetlen értesítése
                            sem!</Text>
                    </View>
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#393939',
        flex: 1,
        width: '100%',
        height: height - 90
    },
    webview: {
        flex: 1,
        width: '100%',
        height: height - 90
    },
    animationContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    lottie: {
        width: 600,
        height: 600
    },
    background: {
        flex: 1,
        background: '#393939',
        width: '100%',
        height: height - 90
    },
});
