import {registerRootComponent} from 'expo';
import 'firebase/messaging';

import App from './App';

export const Toast_Alert_Visibility_Time = 5000;
export const Toast_Alert_Position = "top";
export const Toast_Alert_Duration = "LONG";
export const Toast_Alert_Gravity = "CENTER";
export const Toast_Alert_Shadow = true;
export const Toast_Alert_Animation = true;
export const Toast_Alert_Icon = true;


// Initialize Firebase with your config
const firebaseConfig = {
    apiKey: "AIzaSyAy_We4sXc8ch1SCSrVbdNNmAHg6fhq0cY",
    authDomain: "happysolutions-services.firebaseapp.com",
    projectId: "happysolutions-services",
    storageBucket: "happysolutions-services.appspot.com",
    messagingSenderId: "317446733459",
    appId: "1:317446733459:android:a142c7edfdd0d211e15766",
    measurementId: "9871750265"
};

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
