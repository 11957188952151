import React, { useState, useEffect } from "react";
import { Provider } from "react-native-paper";
import { StatusBar, StyleSheet, Platform } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { theme } from "./app/core/theme";
import {
    HomeScreen,
    LoginScreen,
    NotificationsScreen,
    StartScreen,
    SuccessEntryScreen,
    SuccessPaymentScreen,
    TermsOfServicesScreen,
    PaymentScreen,
    ResultScreen,
    WaitingForPayment,
    FailedPaymentScreen,
    TicketEntryScreen,
    FailedEntryScreen,
    ScanDevicesScreen,
    PaymentsSettingsScreen,
} from "./app/screens";
import Toast from 'react-native-toast-message';
import SplashScreenComponent from './app/screens/SplashScreen';
import * as Font from 'expo-font';
import { Ionicons, Feather, FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { CartProvider } from "./app/helpers/CartContext";
import { AndroidSystemProvider } from "./app/helpers/SystemContext";
import PrivacyPolicyScreen from "./app/screens/PrivacyPolicyScreen";

const Stack = createStackNavigator();

export default function App({ navigation }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isSplashVisible, setIsSplashVisible] = useState(true);

    useEffect(() => {
        const loadFont = async () => {
            await Font.loadAsync({
                ...Ionicons.font,
                ...Feather.font,
                ...FontAwesome.font,
                ...MaterialCommunityIcons.font,
            });
        };

        loadFont();
    }, []);

    const handleAnimationFinish = () => {
        setIsSplashVisible(false);
    };

    if (isSplashVisible) {
        return <SplashScreenComponent onAnimationFinish={handleAnimationFinish} />;
    }

    return (
        <Provider theme={theme}>
            <NavigationContainer>
                <CartProvider>
                    <SystemProvider>
                        <StatusBar hidden={true} backgroundColor="transparent" barStyle="dark-content" />
                        <Stack.Navigator initialRouteName={!isLoggedIn ? "StartScreen" : "HomeScreen"}>
                            <Stack.Screen
                                name="StartScreen"
                                component={StartScreen}
                                options={{
                                    title: 'Kezdőképernyő',
                                    headerShown: false,
                                    gestureEnabled: false,
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    },
                                    headerLeft: null, // Disable left icon
                                    headerRight: null, // Disable right icon
                                }}
                            />
                            <Stack.Screen
                                name="LoginScreen"
                                component={LoginScreen}
                                options={{
                                    title: 'Bejelentkezés',
                                    headerShown: false,
                                    gestureEnabled: false,
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    },
                                    headerLeft: null, // Disable left icon
                                    headerRight: null, // Disable right icon
                                }}
                            />
                            <Stack.Screen
                                name="HomeScreen"
                                component={HomeScreen}
                                options={{
                                    title: 'POS felület',
                                    headerShown: false,
                                    gestureEnabled: false,
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    },
                                }}
                            />
                            <Stack.Screen
                                name="TermsOfServicesScreen"
                                component={TermsOfServicesScreen}
                                options={{
                                    headerShown: true,
                                    title: "Felhasználási feltételek",
                                    headerTitleAlign: 'center', // Center align the title text
                                    backTitle: "Vissza",
                                    gestureEnabled: false,
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    },
                                }}
                            />
                            <Stack.Screen
                                name="PrivacyPolicyScreen"
                                component={PrivacyPolicyScreen}
                                options={{
                                    headerShown: true,
                                    title: "Adatvédelmi nyilatkozat",
                                    headerTitleAlign: 'center', // Center align the title text
                                    backTitle: "Vissza",
                                    gestureEnabled: false,
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    },
                                }}
                            />
                            <Stack.Screen
                                name="ScanDevicesScreen"
                                component={ScanDevicesScreen}
                                options={{
                                    headerShown: true,
                                    title: "Vezeték nélküli eszközök",
                                    headerTitleAlign: 'center', // Center align the title text
                                    backTitle: "Vissza",
                                    gestureEnabled: false,
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    }
                                }}
                            />
                            <Stack.Screen
                                name="PaymentsSettingsScreen"
                                component={PaymentsSettingsScreen}
                                options={{
                                    headerShown: true,
                                    title: "Fizetési és adóügyi eszközök",
                                    headerTitleAlign: 'center', // Center align the title text
                                    backTitle: "Vissza",
                                    gestureEnabled: false,
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    }
                                }}
                            />
                            <Stack.Screen
                                name="NotificationsScreen"
                                component={NotificationsScreen}
                                options={{
                                    headerShown: true,
                                    title: "Értesítések",
                                    headerTitleAlign: 'center', // Center align the title text
                                    backTitle: "Vissza",
                                    leftIconColor: '#ffffff',
                                    gestureEnabled: false,
                                    headerStyle: {
                                        backgroundColor: '#393939', // Set the desired background color
                                    },
                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                    headerTitleStyle: {
                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                    },
                                    icon: "#FEFEFE",
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 100 } },
                                        close: { animation: 'timing', config: { duration: 100 } },
                                    },
                                }}
                            />
                            <Stack.Screen
                                name="PaymentScreen"
                                component={PaymentScreen}
                                options={{
                                    headerShown: false,
                                    title: "Fizetés",
                                    headerTitleAlign: 'center', // Center align the title text
                                    backTitle: "Vissza",
                                    leftIconColor: '#ffffff',
                                    gestureEnabled: false,
                                    headerStyle: {
                                        backgroundColor: '#393939', // Set the desired background color
                                    },
                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                    headerTitleStyle: {
                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                    },
                                    icon: "#FEFEFE",
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 100 } },
                                        close: { animation: 'timing', config: { duration: 100 } },
                                    },
                                    headerLeft: null, // Disable left icon
                                    headerRight: null, // Disable right icon
                                }}
                            />
                            <Stack.Screen
                                name="SuccessPayment"
                                component={SuccessPaymentScreen}
                                options={{
                                    title: 'Sikeres fizetés!',
                                    headerShown: false,
                                    gestureEnabled: false,
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    },
                                    headerLeft: null, // Disable left icon
                                    headerRight: null, // Disable right icon
                                }}
                            />
                            <Stack.Screen
                                name="FailedPayment"
                                component={FailedPaymentScreen}
                                options={{
                                    headerShown: false,
                                    gestureEnabled: false,
                                    title: 'Sikertelen fizetés!',
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 500 } },
                                        close: { animation: 'timing', config: { duration: 500 } },
                                    },
                                    headerLeft: null, // Disable left icon
                                    headerRight: null, // Disable right icon
                                }}
                            />
                            <Stack.Screen
                                name="TicketEntry"
                                component={TicketEntryScreen}
                                options={{
                                    headerShown: true,
                                    title: "Beléptetés",
                                    headerTitleAlign: 'center', // Center align the title text
                                    gestureEnabled: false,
                                    headerStyle: {
                                        backgroundColor: '#393939', // Set the desired background color
                                    },
                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                    headerTitleStyle: {
                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                    },
                                    icon: "#FEFEFE",
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 100 } },
                                        close: { animation: 'timing', config: { duration: 100 } },
                                    }
                                }}
                            />
                            <Stack.Screen
                                name="SuccessEntryScreen"
                                component={SuccessEntryScreen}
                                options={{
                                    headerShown: false,
                                    title: "Sikeres beléptetés!",
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 100 } },
                                        close: { animation: 'timing', config: { duration: 100 } },
                                    },
                                    headerLeft: null, // Disable left icon
                                    headerRight: null, // Disable right icon
                                }}
                            />
                            <Stack.Screen
                                name="FailedEntryScreen"
                                component={FailedEntryScreen}
                                options={{
                                    headerShown: false,
                                    title: "Sikertelen beléptetés!",
                                    transitionSpec: {
                                        open: { animation: 'timing', config: { duration: 100 } },
                                        close: { animation: 'timing', config: { duration: 100 } },
                                    },
                                    headerLeft: null, // Disable left icon
                                    headerRight: null, // Disable right icon
                                }}
                            />
                        </Stack.Navigator>
                    </SystemProvider>
                </CartProvider>
            </NavigationContainer>
            <Toast />
        </Provider>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});