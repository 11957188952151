import BluetoothSerial from './BluetoothSerial';

const btSerial = new BluetoothSerial();

export async function sendFiscatSerialCommand(qrData) {

    try {
        // Scan for the device by name
        await btSerial.scanForDevices();
        alert('Device found');

        // Connect and discover service and characteristic UUIDs
        await btSerial.connectAndDiscoverServices();
        alert('Connected and discovered services');

        // Send data
        await btSerial.sendData(qrData);
        alert("Data sent successfully");

    } catch (error) {
        alert(JSON.stringify(error));
    } finally {
        await btSerial.disconnect();
        alert('Disconnected from device');
    }
}