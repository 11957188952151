// CustomHeader.js
import React from 'react';
import {Animated, Dimensions, StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import IonIcons from 'react-native-vector-icons/Ionicons';
import {useNavigation} from "@react-navigation/native";

const CustomHeader = (props) => {
    const navigation = useNavigation();
    const inputRef = React.useRef(null);
    const [searchText, setSearchText] = React.useState('');
    const [overlayVisible, setOverlayVisible] = React.useState(false);
    const overlayOpacity = React.useRef(new Animated.Value(0)).current;

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const startAnimation = () => {
        Animated.timing(overlayOpacity, {
            toValue: 1,
            duration: 700,
            useNativeDriver: true,
        }).start()
    };

    const endAnimation = () => {
        Animated.timing(overlayOpacity, {
            toValue: 0,
            duration: 700,
            useNativeDriver: true,
        }).start()
    };

    React.useEffect(() => {
        if (searchText.length > 0) {
            startAnimation();
        } else {
            endAnimation();
        }
    });

    return (<>

            <View style={[styles.header, {zIndex: 9999999}]}>
                <TouchableOpacity onPress={() => navigation.navigate("TicketEntry")} activeOpacity={0.9}>
                    <Icon name="ticket" size={30} style={[styles.icon]}/>
                </TouchableOpacity>

            <View style={styles.searchContainer}>
                <Icon name="search" size={20} style={styles.searchIcon}/>
                <TextInput
                    ref={inputRef}
                    style={[styles.searchInput, { color: searchText.length > 0 ? '#fff' : '#575757' }]}
                    placeholder="Keresés..."
                    onPress={focusInput}
                    value={searchText} onChangeText={setSearchText}
                />
            </View>
            <TouchableOpacity activeOpacity={0.6} onPress={() => navigation.navigate("NotificationsScreen")}><IonIcons
                name="notifications" size={30} style={styles.icon}/></TouchableOpacity>
        </View>
        {searchText.length > 0 && <Animated.View style={[styles.overlay, {opacity: overlayOpacity}]}>
            </Animated.View>}
        </>
    );
};

const styles = StyleSheet.create({
    header: {
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        border: 0,
    },
    icon: {
        paddingHorizontal: 10,
        color: '#6e6e6e',
    },
    searchContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: '#bebebe',
        borderWidth: 1,
        borderRadius: 20,
        paddingHorizontal: 15,
        marginHorizontal: 15,
    },
    searchIcon: {
        marginRight: 10,
        color: '#aaa',
    },
    searchInput: {
        flex: 1,
        height: 40,
        zIndex: 99999,
    },
    overlay: {
        flex: 1,
        width: '100%',
        height: Dimensions.get('screen').height + 50,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.85)',
        marginTop: 0,
        zIndex: 9999,
    },
});

export default CustomHeader;
