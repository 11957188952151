import React, { useState, useEffect } from 'react';
import {
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Switch,
    Text,
    TouchableOpacity,
    View
} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { LinearGradient } from "expo-linear-gradient";
import AsyncStorage from "@react-native-async-storage/async-storage";

const cardTerminals = ['Viva Payments', 'Novo Terminal', 'Teya'];
const cashierDevices = [{name: 'Fiscat pénzárgép', value: 'fiscatCashier'}, {name: 'Fiscat adóügyi nyomtató', value: 'fiscatFiscal'}, {name: 'Sam4s QR kód', value: 'sam4sQR'}, {name: 'Micra soros', value: 'micraSerial'}];

export default function PaymentsSettingsScreen({ navigation }) {
    const [selectedCardTerminal, setSelectedCardTerminal] = useState('');
    const [selectedCashierDevice, setSelectedCashierDevice] = useState('');

    useEffect(() => {
        const loadSettings = async () => {
            const storedCardTerminal = await AsyncStorage.getItem('selectedCardTerminal');
            const storedCashierDevice = await AsyncStorage.getItem('selectedCashierDevice');
            if (storedCardTerminal) setSelectedCardTerminal(storedCardTerminal);
            if (storedCashierDevice) setSelectedCashierDevice(storedCashierDevice);
        };
        loadSettings();
    }, []);

    const handleCardTerminalChange = async (terminal) => {
        setSelectedCardTerminal(terminal);
        await AsyncStorage.setItem('selectedCardTerminal', terminal);
    };

    const handleCashierDeviceChange = async (device) => {
        setSelectedCashierDevice(device);
        await AsyncStorage.setItem('selectedCashierDevice', device);
    };

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ flex: 1, width: '100%', height: '100%' }}
        >
            <SafeAreaView style={{ flex: 1, backgroundColor: 'transparent', paddingTop: 10 }}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
                <ScrollView contentContainerStyle={styles.content}>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Bankkártya szolgáltatók</Text>
                        <View style={styles.sectionBody}>
                            {cardTerminals.map((terminal, index) => (
                                <TouchableOpacity
                                    key={index}
                                    style={styles.row}
                                    activeOpacity={1}
                                    onPress={() => handleCardTerminalChange(terminal)}
                                >
                                    <Text style={styles.rowLabel}>{terminal}</Text>
                                    <View style={styles.rowSpacer} />
                                    <Switch
                                        value={selectedCardTerminal === terminal}
                                        onValueChange={() => handleCardTerminalChange(terminal)}
                                    />
                                </TouchableOpacity>
                            ))}
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Pénztárgépek és adóügyi nyomtatók</Text>
                        <View style={styles.sectionBody}>
                            {cashierDevices.map((device, index) => (
                                <TouchableOpacity
                                    key={index}
                                    style={styles.row}
                                    activeOpacity={1}
                                    onPress={() => handleCashierDeviceChange(device.value)}
                                >
                                    <Text style={styles.rowLabel}>{device.name}</Text>
                                    <View style={styles.rowSpacer} />
                                    <Switch
                                        value={selectedCashierDevice === device.value}
                                        onValueChange={() => handleCashierDeviceChange(device.value)}
                                    />
                                </TouchableOpacity>
                            ))}
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingHorizontal: 16,
    },
    headerAction: {
        width: 40,
        height: 40,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    headerTitle: {
        fontSize: 19,
        fontWeight: '600',
        color: '#000',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        textAlign: 'center',
    },
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent'
    },
    section: {
        paddingVertical: 12,
    },
    sectionTitle: {
        margin: 8,
        marginLeft: 12,
        fontSize: 13,
        letterSpacing: 0.33,
        fontWeight: '500',
        color: '#a69f9f',
        textTransform: 'uppercase',
    },
    sectionBody: {
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.5,
        shadowRadius: 1.41,
        elevation: 3,
    },
    row: {
        height: 44,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 12,
        paddingLeft: 16,
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderColor: '#f0f0f0',
    },
    rowLabel: {
        fontSize: 16,
        letterSpacing: 0.24,
        color: '#000',
    },
    rowSpacer: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
    },
});