import React, { useEffect, useState, useRef } from 'react';
import { View, StyleSheet, Dimensions, Animated, Text, Platform, Image, StatusBar } from 'react-native';
import LottieView from 'lottie-react-native';
import { SharedElement } from "react-navigation-shared-element";
import * as SplashScreen from "expo-splash-screen/build/index";
const LogoImage = require("../../assets/icon.png");

const SplashScreenComponent = ({ onAnimationFinish }) => {
    const [animationFinished, setAnimationFinished] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#F1B34B');
    const logoOpacity = useRef(new Animated.Value(0)).current;
    const animation = useRef(null);

    useEffect(() => {
        SplashScreen.hideAsync();
    }, []);

    const handleAnimationFinish = async () => {
        setBackgroundColor('#F1B34B');
        setAnimationFinished(true);

        Animated.timing(logoOpacity, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true,
        }).start(() => {
            setTimeout(() => {
                onAnimationFinish();
            }, 3000); // Show for 3 seconds
        });
    };

    return (
        <View style={[styles.container, { backgroundColor }]}>
            <StatusBar hidden={true} backgroundColor="transparent" barStyle="dark-content" />
            {animationFinished && (
                <Animated.View style={[styles.logoContainer, { opacity: logoOpacity }]}>
                    <SharedElement id="logo">
                        {Platform.OS !== "web" && <Image
                            source={LogoImage}
                            style={[styles.logo, { width: 150, height: 150 }]}
                        />}
                        {Platform.OS === "web" && <img
                            alt={"Happy Solutions Logo"}
                            src={'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png'}
                            style={styles.logo}
                        />}
                    </SharedElement>
                    <Text style={[styles.logo, {marginTop: 40}]}>Happy Services</Text>
                    <Text style={[styles.logo, {fontSize: 15}]}>a Happy Solution software</Text>
                </Animated.View>
            )}
            {!animationFinished && <LottieView
                ref={animation}
                source={require('../../assets/animations/splashscreen.json')} // Path to your Lottie animation file
                autoPlay
                loop={false}
                speed={0.7}
                onAnimationFinish={handleAnimationFinish}
                style={styles.lottie}
            />}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
    lottie: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        padding: 0,
        margin: 0,
    },
    logoContainer: {
        position: 'absolute',
        alignItems: 'center',
        zIndex: 99999999,
    },
    logo: {
        fontSize: 40,
        fontWeight: 'bold',
        color: 'white',
    },
    text: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 10,
    },
});

export default SplashScreenComponent;