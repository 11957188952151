import React, {useState} from 'react';
import {
    Alert,
    Image,
    Linking, Platform,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Switch,
    Text,
    TouchableOpacity,
    View
} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {LinearGradient} from "expo-linear-gradient";
import {getAccessToken, getUserInfo, removeAccessToken, removeUserInfo} from "../helpers/storage";
import AsyncStorage from "@react-native-async-storage/async-storage";


import appConfig from '../../app.json';
import PrivacyPolicyScreen from "./PrivacyPolicyScreen";
const { version } = appConfig.expo;


export default function SettingsScreen({navigation}) {
    const [form, setForm] = useState({
        emailNotifications: false,
        pushNotifications: true,
        smsNotifications: false,
    });
    const [enablePhoneLinking, setEnablePhoneLinking] = useState(false);
    const [restaurantPlace, setRestaurantPlace] = useState({name: '', raw: ''});
    const [currentUser, setCurrentUser] = useState({name: {first: '', last: ''}, email: '', photo: ''});
    const [accessToken, setAccessToken] = useState('');

    React.useEffect(() => {
        const setDatas = async () => {
            const restaurantPlaceStored = await AsyncStorage.getItem("restaurantPlace");
            setRestaurantPlace(JSON.parse(restaurantPlaceStored));

            const userInfo = await getUserInfo();
            setCurrentUser(userInfo);

            const token = await getAccessToken();
            setAccessToken(token);
        }
        setDatas();
    }, [navigation])

    const openPhoneCall = () => {
        const phoneNumber = 'tel:+3613009105'; // Replace with your phone number
        Linking.canOpenURL(phoneNumber)
            .then((supported) => {
                if (supported) {
                    Linking.openURL(phoneNumber);
                } else {
                    console.log("Don't know how to open URI: " + phoneNumber);
                }
            })
            .catch((err) => console.error('An error occurred', err));
    };

    const openEmail = (subject = "Üzenet az alkalmazásból") => {
        const email = 'mailto:support@happygastro.hu/?subject=' + subject; // Replace with your email address
        Linking.canOpenURL(email)
            .then((supported) => {
                if (supported) {
                    Linking.openURL(email);
                } else {
                    console.log("Don't know how to open URI: " + email);
                }
            })
            .catch((err) => console.error('An error occurred', err));
    };
    const openAdmin = async () => {
        const link = 'https://admin.happygastro.hu/?token='+ await getAccessToken(); // Replace with your email address
        Linking.canOpenURL(link)
            .then((supported) => {
                if (supported) {
                    Linking.openURL(link);
                } else {
                    console.log("Don't know how to open URI: " + link);
                }
            })
            .catch((err) => console.error('An error occurred', err));
    };

    React.useEffect(() => {
        if(Platform.OS === 'web') return setEnablePhoneLinking(false);
        const phoneNumber = 'tel:+3613009105'; // Replace with your phone number
        Linking.canOpenURL(phoneNumber)
            .then((supported) => {
                if (supported) {
                    setEnablePhoneLinking(true)
                } else {
                    setEnablePhoneLinking(false)
                }
            })
            .catch((err) => setEnablePhoneLinking(false));
    }, [navigation])

    return (
        <LinearGradient
            // Replace colors with the ones matching the image you provided
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}  // Example gradient colors
            style={{flex: 1, width: '100%', height: '100%'}}
        ><SafeAreaView style={{flex: 1, backgroundColor: 'transparent', paddingTop: 10}}>
            <StatusBar backgroundColor="transparent" translucent barStyle="dark-content"/>
            <View style={styles.header}>
                <View style={styles.headerAction}>
                </View>

                <Text numberOfLines={1} style={styles.headerTitle}>
                    Fiókom és beállítás
                </Text>

                {false && <View style={[styles.headerAction, {alignItems: 'flex-end'}]}>
                    <TouchableOpacity
                        onPress={() => {
                            // handle onPress
                        }}>
                        <FeatherIcon
                            color="#000"
                            name="more-vertical"
                            size={24}/>
                    </TouchableOpacity>
                </View>}
            </View>

            <ScrollView contentContainerStyle={styles.content}>
                <View style={[styles.section, {paddingTop: 4}]}>
                    <Text style={styles.sectionTitle}>Profilom</Text>

                    <View style={styles.sectionBody}>
                        <TouchableOpacity
                            activeOpacity={.9}
                            onPress={() => {
                                // handle onPress
                            }}
                            style={styles.profile}>
                            <Image
                                alt=""
                                source={{
                                    uri: currentUser ? currentUser.photo : 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-vector-illustration_561158-3383.avif',
                                }}
                                style={styles.profileAvatar}/>

                            <View style={styles.profileBody}>
                                <Text style={styles.profileName}>{currentUser.name.first} {currentUser.name.last}</Text>

                                <Text style={styles.profileHandle}>{currentUser.email}</Text>
                            </View>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={22}/>
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Értesítések</Text>

                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <TouchableOpacity
                                onPress={() => {
                                    // handle onPress
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Nyelv</Text>

                                <View style={styles.rowSpacer}/>

                                <Text style={styles.rowValue}>Magyar</Text>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.rowWrapper}>
                            <View style={styles.row}>
                                <Text style={styles.rowLabel}>E-mail értesítések</Text>

                                <View style={styles.rowSpacer}/>

                                <Switch
                                    onValueChange={emailNotifications =>
                                        setForm({...form, emailNotifications})
                                    }
                                    style={{transform: [{scaleX: 0.95}, {scaleY: 0.95}]}} trackColor={'#F1B34B'}
                                    value={form.emailNotifications}/>
                            </View>
                        </View>

                        <View style={[styles.rowWrapper]}>
                            <View style={styles.row}>
                                <Text style={styles.rowLabel}>Push értesítések</Text>

                                <View style={styles.rowSpacer}/>

                                <Switch
                                    onValueChange={pushNotifications =>
                                        setForm({...form, pushNotifications})
                                    }
                                    style={{transform: [{scaleX: 0.95}, {scaleY: 0.95}]}}
                                    value={form.pushNotifications}/>
                            </View>
                        </View>

                        <View style={[styles.rowWrapper, styles.rowLast]}>
                            <View style={styles.row}>
                                <Text style={styles.rowLabel}>SMS értesítések</Text>

                                <View style={styles.rowSpacer}/>

                                <Switch
                                    onValueChange={smsNotifications =>
                                        setForm({...form, smsNotifications})
                                    }
                                    style={{transform: [{scaleX: 0.95}, {scaleY: 0.95}]}} trackColor={'#F1B34B'}
                                    value={form.smsNotifications}/>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Üzlet és eszköz beállítások</Text>

                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <TouchableOpacity
                                activeOpacity={1}
                                onPress={() => {
                                    // handle onPress
                                }}
                                style={styles.row}>
                                <Text style={[styles.rowLabel, {textAlign: 'center', fontWeight: 700}]}>{restaurantPlace.name || restaurantPlace.raw}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={[styles.rowWrapper, styles.rowWrapper]}>
                        <TouchableOpacity
                            onPress={() => {
                                // handle onPress
                                navigation.navigate("ScanDevicesScreen")
                            }}
                            style={styles.row}>
                            <Text style={styles.rowLabel}>Vezeték nélküli eszközök</Text>

                            <View style={styles.rowSpacer}/>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={19}/>
                        </TouchableOpacity>
                    </View>
                    <View style={[styles.rowWrapper, styles.rowWrapper]}>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("PaymentsSettingsScreen");
                            }}
                            style={styles.row}>
                            <Text style={styles.rowLabel}>Fizetési és adóügyi eszközök</Text>

                            <View style={styles.rowSpacer}/>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={19}/>
                        </TouchableOpacity>
                    </View>
                    <View style={[styles.rowWrapper, styles.rowWrapper]}>
                        <TouchableOpacity
                            onPress={() => {
                                // handle onPress
                                Alert.alert("Hamarosan...", "Ez a felület hamarosan integrálásra kerül!")
                            }}
                            style={styles.row}>
                            <Text style={styles.rowLabel}>Nyomtatók és profilok</Text>

                            <View style={styles.rowSpacer}/>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={19}/>
                        </TouchableOpacity>
                    </View>
                    <View style={[styles.rowWrapper, styles.rowLast]}>
                        <TouchableOpacity
                            onPress={() => {
                                // handle onPress
                                openAdmin()
                            }}
                            style={styles.row}>
                            <Text style={styles.rowLabel}>Admin felület</Text>

                            <View style={styles.rowSpacer}/>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={19}/>
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Ügyfélszolgálat</Text>

                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <TouchableOpacity
                                onPress={() => {
                                    openEmail("Észrevétel az alkalmazásból");
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Írj nekünk!</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper]}>
                            <TouchableOpacity
                                onPress={() => {
                                    if (enablePhoneLinking) openPhoneCall()
                                }}
                                style={styles.row}>
                                <Text style={[styles.rowLabel, {color: enablePhoneLinking ? "#a69f9f" : "#a69f9f"}]}>Hívj
                                    minket telefonon!</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.rowWrapper}>
                            <TouchableOpacity
                                onPress={() => {
                                    openEmail("Hibabejelentés az alkalmazásból");
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Hibabejelenés</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>

                        <View style={[styles.rowWrapper]}>
                            <TouchableOpacity
                                onPress={() => {
                                    // handle onPress
                                    navigation.navigate("PrivacyPolicyScreen")
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Adatvédelmi nyilatkozat</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.rowWrapper, styles.rowLast]}>
                            <TouchableOpacity
                                onPress={() => {
                                    // handle onPress
                                    navigation.navigate("TermsOfServicesScreen")
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Felhasználási feltételek</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>

                <Text style={styles.contentFooter}>Alkalmazás verzió v{version}</Text>

                <View style={[styles.section, {paddingBottom: 30}]}>
                    <View style={styles.sectionBody}>
                        <View
                            style={[
                                styles.rowWrapper,
                                styles.rowFirst,
                                styles.rowLast,
                                {alignItems: 'center'},
                            ]}>
                            <TouchableOpacity
                                onPress={async () => {
                                    await removeUserInfo();
                                    await removeAccessToken();



                                    return navigation.navigate("StartScreen");
                                }}
                                style={styles.row}>
                                <Text style={[styles.rowLabel, styles.rowLabelLogout]}>
                                    Kijelentkezés
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView></LinearGradient>
    );
}

const styles = StyleSheet.create({
    /** Header */
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingHorizontal: 16,
    },
    headerAction: {
        width: 40,
        height: 40,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    headerTitle: {
        fontSize: 19,
        fontWeight: '600',
        color: '#000',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        textAlign: 'center',
    },
    /** Content */
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent'
    },
    contentFooter: {
        marginTop: 0,
        fontSize: 13,
        fontWeight: '500',
        textAlign: 'center',
        color: '#a69f9f',
    },
    /** Section */
    section: {
        paddingVertical: 12,
    },
    sectionTitle: {
        margin: 8,
        marginLeft: 12,
        fontSize: 13,
        letterSpacing: 0.33,
        fontWeight: '500',
        color: '#a69f9f',
        textTransform: 'uppercase',
    },
    sectionBody: {
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.5,
        shadowRadius: 1.41,
        elevation: 3,
    },
    /** Profile */
    profile: {
        padding: 12,
        backgroundColor: '#FEFEFE',
        borderRadius: 12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    profileAvatar: {
        width: 60,
        height: 60,
        borderRadius: 9999,
        marginRight: 12,
    },
    profileBody: {
        marginRight: 'auto',
    },
    profileName: {
        fontSize: 18,
        fontWeight: '600',
        color: '#292929',
    },
    profileHandle: {
        marginTop: 2,
        fontSize: 16,
        fontWeight: '400',
        color: '#858585',
    },
    /** Row */
    row: {
        height: 44,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 12,
    },
    rowWrapper: {
        paddingLeft: 16,
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderColor: '#f0f0f0',
    },
    rowFirst: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
    },
    rowLabel: {
        fontSize: 16,
        letterSpacing: 0.24,
        color: '#000',
    },
    rowSpacer: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
    },
    rowValue: {
        fontSize: 16,
        fontWeight: '500',
        color: '#ababab',
        marginRight: 4,
    },
    rowLast: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
    },
    rowLabelLogout: {
        width: '100%',
        textAlign: 'center',
        fontWeight: '600',
        color: '#dc2626',
    },
});
