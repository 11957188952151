import React, {useCallback, useState} from 'react';
import {
    Dimensions,
    FlatList,
    Platform,
    RefreshControl,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Image,
    Vibration
} from 'react-native';
import {LinearGradient} from "expo-linear-gradient";
import CustomHeader from "../helpers/CustomHeader";
import {getAccessToken} from "../helpers/storage";
import Toast from "react-native-toast-message";
import PlaceholderImage from "../../assets/placeholder.png"
import { CartContext } from '../helpers/CartContext';
import {generateObjectId} from "../helpers/objectId";
import {priceFormatter} from "../helpers/numbers";
import {isColorDark} from "../helpers/colors";
import LottieView from "lottie-react-native";
import dataLoadingAnimation from "../../assets/animations/data-loading.json";

const {height, width} = Dimensions.get('window');

const EventBox = (props) => {
    const {ev, onClickEvent} = props;

    const setEvent = () => {
        Vibration.vibrate(30);
        props.onClickEvent(ev)
    }
    const isDark = isColorDark(ev.color || '#fefe');

    return (
        <TouchableOpacity
            style={{width: '100%', backgroundColor: ev.color ? ev.color : '#fff'}}
            onPress={() => setEvent()}
        >
            {Platform.OS !== 'web' ? (
                <Image
                    height={80}
                    resizeMode={'cover'}
                    resizeMethod={'auto'}
                    style={[styles.productImage, { width: '100%', height: 80, resizeMode: 'cover' }]}
                    source={PlaceholderImage}
                />
            ) : (
                <img width={'100%'} src="https://placehold.co/100x102" style={styles.productImage} alt="product"/>
            )}
            <Text style={[styles.productName, { color: isDark ? '#fff' : '#000' }]}>{ev.name}</Text>
        </TouchableOpacity>
    );
};


const TicketRow = (props) => {
    const { ticket, onClickEvent } = props;
    const isDark = isColorDark(ticket.color || '#fefe');

    const setEvent = () => {
        Vibration.vibrate(30);
        onClickEvent(ticket);
    };

    return (
        <TouchableOpacity
            style={[styles.ticketRow, { backgroundColor: ticket.color || '#fefe' }]}
            onPress={setEvent}
        >
            <Text style={[styles.ticketName, { color: isDark ? '#fff' : '#000' }]}>{ticket.name}</Text>
            <Text style={[styles.ticketPrice, { color: isDark ? '#fff' : '#000' }]}>
                {priceFormatter(ticket.price)}
            </Text>
        </TouchableOpacity>
    );
};

const EventsList = (props) => {
    const renderItem = ({ item, index }) => {
        const isLastItem = index === props.events.length - 1;
        const isOdd = props.events.length % 2 !== 0;

        return (
            <View style={[styles.productBox, isLastItem && isOdd ? styles.fullWidth : null]}>
                <EventBox ev={item} onClickEvent={props.onClickEvent} />
            </View>
        );
    };

    return (
        <View style={{flex: 1}}><FlatList
            data={props.events}
            renderItem={renderItem}
            keyExtractor={item => item.id}
            numColumns={2}
            contentContainerStyle={styles.container}
        />
        </View>
    );
};

const TicketList = (props) => {
    const Breadcrumb = () => (
        <TouchableOpacity onPress={props.goBack} style={styles.breadcrumbContainer}>
            <Text style={styles.breadcrumbText}>{'<'} {props?.currentEvent?.name || 'Jegyek'}</Text>
        </TouchableOpacity>
    );

    return <View style={{flex: 1}}>
        <Breadcrumb/>
        <FlatList
            data={props.events.find(e => e.id === props.currentEvent.id).tickets}
            renderItem={({item}) => <TicketRow ticket={item} onClickEvent={() => props.onClickTicket(item)}/>}
            keyExtractor={item => item.id}
            numColumns={1}
            contentContainerStyle={[styles.container, {marginTop: 12}]}
        />
    </View>
};

const SaleScreen = () => {
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [events, setEvents] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch('https://api.happygastro.hu/api/tickets/events/all', {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            const data = await response.json();
            console.log(data.events || []);
            setEvents(data.events || [])
            setLoading(false);
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: 'Nem sikerült az események lekérdezés a MyTix rendszeréből',
                text2: 'Kérlek, hogy próbáld meg újraindítani a szoftvert vagy írj nekünk!',
                position: 'top',
                visibilityTime: 2000,
            })
        }
    };

    const onRefresh = useCallback(() => {
        setRefreshing(true);
        fetchData().then(() => setRefreshing(false));
    }, []);

    React.useEffect(() => {
        fetchData();
    }, []);

    const { cartItems, addToCart, removeFromCart } = React.useContext(CartContext);


    if(!events.length) {
        return <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={styles.background}
            start={{x: 0.3, y: 0}}
            end={{x: 0.7, y: 1}}
        ><View style={[styles.container, {
            flex: 1,
            alignContent: 'center',
            alignItems: 'center'
        }]}>
            <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 60
            }}>
                <LottieView
                    source={dataLoadingAnimation}
                    autoPlay
                    loop={true}
                    style={styles.lottie}
                    renderMode={'AUTOMATIC'}
                    resizeMode={'center'}
                    enableSafeModeAndroid={true}
                />
                <Text style={{fontSize: 22, color: 'black', marginTop: 10}}>Adatok letöltése...</Text>
            </View>
        </View>
        </LinearGradient>
    }

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={styles.background}
            start={{x: 0.3, y: 0}}
            end={{x: 0.7, y: 1}}
        >
            <ScrollView style={{width: '100%', flex: 1, padding: 0, margin: 0, height: '100%'}} refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
            }>
                <CustomHeader title="Értékesítés"/>
                <View style={styles.container}>

                    {!selectedEvent && <EventsList events={events} onClickEvent={setSelectedEvent} onBack={() => setSelectedEvent(null)}/>}
                    {selectedEvent && <TicketList events={events} goBack={() => setSelectedEvent(null)} currentEvent={selectedEvent} onClickTicket={(ticket) => {
                        addToCart({...ticket, quantity: 1, _id: generateObjectId()});
                        Toast.show({
                            type: 'info',
                            text2: 'Bekerült a kosárba: ' + ticket.name,
                            position: 'bottom',
                            visibilityTime: 700,
                        });
                    }}/>}

                </View>
            </ScrollView>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    breadcrumbContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        backgroundColor: '#f1f1f1',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    breadcrumbText: {
        fontSize: 20,
        color: '#333',
        fontWeight: 'bold',
    },
    container: {
        flex: 1,
        height: '100%',
        padding: 6,
        width: '100%'
    },
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        height: height,
    },
    ticketRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        marginHorizontal: 10,
        marginBottom: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
        elevation: 3,
    },
    ticketName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    ticketPrice: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    balanceBox: {
        padding: 20,
        backgroundColor: '#f1b34b',
        borderRadius: 10,
        marginBottom: 20,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 4},
        shadowOpacity: 1,
        shadowRadius: 2.84,
        elevation: 20,
    },
    balanceText: {
        fontSize: 24,
        color: '#fff',
        fontWeight: 'bold',
    },
    transactionList: {
        paddingBottom: 20,
    },
    transactionItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 10,
        marginBottom: 10,
    },
    transactionDescription: {
        fontSize: 16,
    },
    transactionAmount: {
        fontSize: 16,
        fontWeight: 'bold',
    },

    productBox: {
        flex: 1,
        margin: 5,
        maxWidth: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderRadius: 4,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
        elevation: 5,
    },
    fullWidth: {
        flex: 1,
        width: '100%',
    },
    productImage: {
        width: "100%",
        height: 80,
        marginBottom: 0,
        cover: 'cover',
        objectFit: 'cover',
    },
    productName: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 5
    },
    lottie: {
        width: 600,
        height: 600
    },
});

export default SaleScreen;
