import React from "react";
import {Alert, Image, Platform, StatusBar, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {SharedElement} from 'react-navigation-shared-element';
import {LinearGradient} from "expo-linear-gradient";
import * as LocalAuthentication from 'expo-local-authentication';
import {getAccessToken} from "../helpers/storage";
import LogoImage from "../../assets/icon.png";
import * as SplashScreen from "expo-splash-screen/build/index";

let tryCount = 0;

const StartScreen = ({navigation}) => {
    const [logout, setLogout] = React.useState(true);
    const [turnOff, setTurnOff] = React.useState(true);
    const [token, setToken] = React.useState(undefined);

    React.useEffect(() => {
        SplashScreen.hideAsync();
        const checkLogin = async () => {
            const getToken = await getAccessToken();
            if (getToken) {
                setTurnOff(false);
                setToken(getToken);
                setLogout(false);
            }
        };
        checkLogin();
    }, [navigation]);

    const authenticate = async () => {
        if (!token) return; // Only authenticate if the user is logged in
        if(Platform.OS === "web") return navigation.navigate('HomeScreen');
        try {
            tryCount++;
            const hasHardware = await LocalAuthentication.hasHardwareAsync();
            if (!hasHardware) {
                return navigation.navigate('HomeScreen');
            }

            const isEnrolled = await LocalAuthentication.isEnrolledAsync();
            if (!isEnrolled) {
                return navigation.navigate('HomeScreen');
            }

            const result = await LocalAuthentication.authenticateAsync({
                promptMessage: "Hitelesítse magát az alkalmazás eléréséhez",
                fallbackLabel: "Használjon jelszót"
            });

            if (result.success) {
                return navigation.navigate('HomeScreen');
            } else {
                authenticate();
            }
        } catch (error) {
            return navigation.navigate('HomeScreen');
        }
    };

    React.useEffect(() => {
        if (logout && token) {
            setTimeout(() => {
                authenticate();
            }, 700);
        }
    }, [navigation, logout, token]);

    return (<LinearGradient
            // Replace colors with the ones matching the image you provided
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}  // Example gradient colors
            style={styles.background}
        >
            <StatusBar backgroundColor="transparent" translucent barStyle="dark-content"/>
            <View style={styles.container}>
                <SharedElement id="logo">
                    {Platform.OS !== "web" && <Image
                        source={LogoImage}
                        style={[styles.logo, { width: 150, height: 150 }]}
                    />}
                    {Platform.OS === "web" && <img
                        alt={"Happy Solutions Logo"}
                        src={'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png'}
                        style={styles.logo}
                    />}
                </SharedElement>
                <Text style={styles.title}>Üdvözöljük a Happy Services-ben!</Text>
                <Text style={styles.description}>
                    Happy Solutions - Megoldásokat szállítunk!
                </Text>
                <TouchableOpacity style={styles.loginButton}
                                  onPress={() => logout ? navigation.navigate("LoginScreen") : authenticate()}>
                    <Text style={styles.loginButtonText}>Kezdjünk hozzá!</Text>
                </TouchableOpacity>
            </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    logo: {
        width: 150,
        height: 150,
        marginBottom: 80,
        backgroundColor: 'transparent'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center'
    },
    description: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },
    loginButton: {
        backgroundColor: "#F1B34B",
        paddingVertical: 15,
        paddingHorizontal: 30,
        borderRadius: 5,
    },
    loginButtonText: {
        fontSize: 24,
        color: "white",
        paddingHorizontal: 20,
        textAlign: 'center',
    },
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default StartScreen;
