import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Dimensions, Switch, Linking, Alert } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import Spinner from "react-native-loading-spinner-overlay";
import { priceFormatter } from "../helpers/numbers";
import AsyncStorage from "@react-native-async-storage/async-storage";
import VivaPaymentModule from "../paymentModules/VivaPaymentsModule";
import {CartContext} from "../helpers/CartContext";
import {sendFiscatSerialCommand} from "../paymentModules/FiscatCashierModule";

const { width, height } = Dimensions.get('window');

const PaymentScreen = ({ navigation }) => {
    const { cartTotal, setCart } = React.useContext(CartContext);

    const [processing, setProcessing] = useState(false);
    const [needInvoice, setNeedInvoice] = useState(false);
    const [paymentType, setPaymentType] = useState(false);
    const [originalAmount] = useState(cartTotal || 0);
    const [amount, setAmount] = useState(cartTotal || 0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [amountToPay, setAmountToPay] = useState(originalAmount);
    const [changeToReturn, setChangeToReturn] = useState(0);

    useEffect(() => {
        const numericAmount = parseInt(amount) || 0;
        const remainingAmount = Math.max(originalAmount - numericAmount, 0);
        const change = Math.max(numericAmount - originalAmount, 0);

        setPaidAmount(numericAmount);
        setAmountToPay(remainingAmount);
        setChangeToReturn(change);
    }, [amount]);

    const handleInput = (value) => {
        setAmount((prev) => (prev === originalAmount ? value : prev + value));
    };

    const handleBackspace = () => {
        setAmount((prev) => (prev.length > 1 ? prev.slice(0, -1) : ''));
    };

    const handleClear = () => {
        setAmount('');
    };

    const handlePaymentTypeChange = (type) => {
        setPaymentType(type);
    };

    useEffect(() => {
        return () => {
            setProcessing(false);
        }
    }, []);


    React.useEffect(() => {
        const callback = (response) => {
            setProcessing(false);
            if(response.status === "success" || response.status === "SUCCESS"){
                setCart([]);
                return navigation.navigate('SuccessPayment', {...response});
            } else {
                return navigation.navigate('Sale', {...response});
            }
        }

        const handleUrl = () => VivaPaymentModule.handleCallbackUrl(url);
        Linking.addEventListener('url', handleUrl);

        return () => Linking.removeAllListeners('url');
    }, []);

    const handleVivaPayment = (paidAmount) => {
        const paymentAmount = Number(paidAmount) * 100; // Amount in minor units (e.g., cents)
        return VivaPaymentModule.initiatePayment(paymentAmount);
    };

    const processCashier = () => {
        sendFiscatSerialCommand("fiscat/AEE|SLD|||||||1|Felnőtt belépőjegy|3|4990|1||P")
    }

    return (
        <View style={styles.container}>
            <Spinner
                visible={processing}
                textContent={'Feldolgozás...'}
                textStyle={styles.spinnerTextStyle}
            />
            {/* Payment Type Tabs */}
            <View style={styles.tabsContainer}>
                {[
                    { type: 'Készpénz', icon: 'dollar-sign' },
                    { type: 'Bankkártya', icon: 'credit-card' },
                    { type: 'Utalvány', icon: 'tag' },
                ].map(({ type, icon }) => (
                    <TouchableOpacity
                        key={type}
                        style={[styles.tab, paymentType === type && styles.activeTab]}
                        onPress={() => handlePaymentTypeChange(type)}
                    >
                        <Feather name={icon} size={18} color={paymentType === type ? '#f1b34b' : '#666'} />
                        <Text style={[styles.tabText, paymentType === type && styles.activeTabText]}>{type}</Text>
                    </TouchableOpacity>
                ))}
            </View>

            {/* Payment Amount Input */}
            <View style={styles.amountContainer}>
                <TouchableOpacity onPress={handleBackspace} style={styles.backspaceButton}>
                    <Text style={styles.backspaceText}>⌫</Text>
                </TouchableOpacity>
                <TextInput
                    style={styles.amountInput}
                    value={amount.toString()}
                    editable={false}
                    keyboardType="numeric"
                    placeholder={'Kapott összeg'}
                    placeholderTextColor={'#AAA'}
                />
            </View>

            {/* Info Lines */}
            <View style={styles.infoContainer}>
                <View style={styles.infoRow}>
                    <Text style={styles.infoLabel}>Fizetendő:</Text>
                    <Text style={styles.infoValue}>{priceFormatter(originalAmount)} Ft</Text>
                </View>
                <View style={[styles.infoRow, { color: Number(amountToPay) > 0 ? "red" : "#333", fontWeight: Number(amountToPay) > 0 ? 900 : 200 }]}>
                    <Text style={[styles.infoLabel, { color: Number(amountToPay) > 0 ? "red" : "#333", fontWeight: Number(amountToPay) > 0 ? 900 : 200 }]}>Még fizetendő:</Text>
                    <Text style={[styles.infoValue, { color: Number(amountToPay) > 0 ? "red" : "#333", fontWeight: Number(amountToPay) > 0 ? 900 : 200 }]}>{priceFormatter(amountToPay)} Ft</Text>
                </View>
                <View style={styles.infoRow}>
                    <Text style={[styles.infoLabel, { fontWeight: Number(changeToReturn) > 0 ? 900 : 200 }]}>Visszajáró:</Text>
                    <Text style={[styles.infoValue, { fontWeight: Number(changeToReturn) > 0 ? 900 : 200 }]}>{priceFormatter(changeToReturn)} Ft</Text>
                </View>
            </View>

            <View style={styles.switchContainer}>
                <Text style={styles.switchLabel}>ÁFÁ-s számla igénylése</Text>
                <Switch
                    vibrate={true}
                    thumbColor="#f1b34b"
                    trackColor={{ false: "#767577", true: "#ecbb69" }}
                    ios_backgroundColor="#3e3e3e"
                    value={needInvoice}
                    onValueChange={setNeedInvoice}
                />
            </View>

            {/* Pinpad and Process Payment Button Container */}
            <View style={styles.bottomContainer}>
                {/* Pinpad */}
                <View style={styles.pinpadContainer}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, '⌫', 0, 'X'].map((value, index) => (
                        <TouchableOpacity
                            key={index}
                            style={styles.pinpadButton}
                            onPress={() => (value === '⌫' ? handleBackspace() : value === 'X' ? handleClear() : handleInput(value.toString()))}
                        >
                            <Text style={styles.pinpadText}>{value}</Text>
                        </TouchableOpacity>
                    ))}
                </View>

                {/* Back and Process Payment Buttons */}
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.backButton} onPress={() => {
                        navigation.navigate('HomeScreen');
                    }}>
                        <Feather name="arrow-left" size={24} color="#fff" />
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.processButton} onPress={async () => {
                        if (!paymentType || paidAmount < originalAmount) return Alert.alert("Hiba", "Kérjük válasszon fizetési módot vagy ellenőrizze a fizetendő összeget!");


                        const paymentTerminal = await AsyncStorage.getItem('selectedCardTerminal');
                        // If Viva Payments activated
                        if (paymentType === "Bankkártya" && (paymentTerminal && paymentTerminal === "Viva Payments")) {
                            alert("Opening Viva Terminal app...")
                            return handleVivaPayment(paidAmount);
                        } else {
                            setProcessing(true);

                            alert("Processing cash payment...")

                            // Sending data to Fiscat Cashier
                            const paymentCashier = await AsyncStorage.getItem('selectedCashierDevice');
                            if(paymentCashier === "fiscatCashier" || paymentCashier === "Fiscat pénzárgép" ) {
                                processCashier()
                            }

                            return setTimeout(() => {

                                setProcessing(false)
                                setCart([]);
                                navigation.navigate("FailedPayment")
                            }, 2000);
                        }
                    }}>
                        <Text style={styles.processButtonText}>Rendelés véglegesítése</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'space-between',
    },
    tabsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 10,
        backgroundColor: '#f1f1f1',
    },
    tab: {
        flex: 1,
        padding: 12,
        alignItems: 'center',
    },
    activeTab: {
        borderBottomWidth: 2,
        borderBottomColor: '#f1b34b',
    },
    tabText: {
        fontSize: 16,
        color: '#666',
        marginTop: 4,
    },
    activeTabText: {
        color: '#f1b34b',
    },
    infoContainer: {
        marginTop: 20,
        padding: 10,
    },
    infoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 2,
    },
    infoLabel: {
        fontSize: 18,
        color: '#333',
        fontWeight: '500',
    },
    infoValue: {
        fontSize: 18,
        color: '#333',
        fontWeight: 'bold',
    },
    amountContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        width: '100%',
    },
    backspaceButton: {
        marginRight: 10,
    },
    backspaceText: {
        fontSize: 26,
        color: '#333',
    },
    amountInput: {
        marginTop: 10,
        fontSize: 32,
        textAlign: 'center',
        color: '#333',
        borderBottomWidth: 1,
        width: width * 0.8,
    },
    clearButton: {
        marginLeft: 10,
    },
    clearText: {
        fontSize: 26,
        color: '#333',
    },
    bottomContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingBottom: 10,
    },
    pinpadContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: width,
        paddingHorizontal: 0,
    },
    pinpadButton: {
        width: width / 3.4,
        height: height / 10,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 5,
        borderColor: '#e8e8e8',
        borderWidth: 0.5
    },
    pinpadText: {
        fontSize: 28,
        color: '#333',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
    },
    backButton: {
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        marginTop: 20,
        borderRadius: 5,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0
    },
    processButton: {
        backgroundColor: 'orange',
        alignItems: 'center',
        padding: 15,
        marginTop: 20,
        flex: 1,
        marginLeft: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5
    },
    processButtonText: {
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },
    spinnerTextStyle: {
        color: '#FFF'
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        marginVertical: 10,
    },
    switchLabel: {
        fontSize: 16,
        color: '#000',
    },
});

export default PaymentScreen;