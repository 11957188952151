import React, { useState, useEffect } from 'react';
import {View, TextInput, StyleSheet, TouchableOpacity, Text, Dimensions, Platform} from 'react-native';
import Icon from "react-native-vector-icons/Feather";
import {LinearGradient} from "expo-linear-gradient";
import { BarCodeScanner } from 'expo-barcode-scanner';

import Spinner from "react-native-loading-spinner-overlay";
import LottieView from "lottie-react-native";
import {getAccessToken} from "../helpers/storage";

const { height } = Dimensions.get('window');

const TicketEntryScreen = ({ onQRCodeScanned, navigation }) => {
    const [scanned, setScanned] = useState(false);
    const [ticketCode, setTicketCode] = useState('');
    const [processing, setProcessing] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);
    const [facing, setFacing] = useState("back");

    useEffect(() => {
        const getBarCodeScannerPermissions = async () => {
            const { status } = await BarCodeScanner.requestPermissionsAsync();
            setHasPermission(status === 'granted');
        };

        getBarCodeScannerPermissions();
    }, []);

    const checkTicketId = async (ticketId) => {
        const accessToken = await getAccessToken()

        try {
            const response = await fetch(`https://api.happygastro.hu/api/tickets/event/enter/${ticketId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            return data.validation || false;
        } catch (error) {
            console.error('Error checking ticket ID:', error);
            throw error;
        }
    };

    const handleBarCodeScanned = ({ data }) => {
        setTicketCode(data);
        setProcessing(true);

        return checkTicketId(data).then((resp) => {
            if (resp.valid) {
                return navigation.navigate("SuccessEntryScreen", { ticketCode: data, reason: resp.reason || "Nem érvényes jegy!" });
            } else {
                return navigation.navigate("FailedEntryScreen", { ticketCode: data, reason: resp.reason || "Nem érvényes jegy!" });
            }
        }).catch((error) => {
            return navigation.navigate("FailedEntryScreen");
        }).finally(() => setProcessing(false));
    };

    const handleSend = () => {
        setProcessing(true);

        return checkTicketId(ticketCode).then((resp) => {
            if (resp.valid) {
                return navigation.navigate("SuccessEntryScreen", { ticketCode: ticketCode, reason: resp.reason || "Nem érvényes jegy!" });
            } else {
                return navigation.navigate("FailedEntryScreen", { ticketCode: ticketCode, reason: resp.reason || "Nem érvényes jegy!" });
            }
        }).catch((error) => {
            return navigation.navigate("FailedEntryScreen", { ticketCode: ticketCode, reason: "Váratlan hiba történt, próbáld újra!" });
        }).finally(() => setProcessing(false));
    };


    const NoCameraAccess = ({message = "Kamera nem érhető el!"}) => (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={styles.background}
            start={{ x: 0.3, y: 0 }}
            end={{ x: 0.7, y: 1 }}
        >
            <LottieView
                source={require('../../assets/animations/no_camera_access.json')}
                autoPlay
                loop
                style={styles.lottie}
            />
            <Text style={styles.noAccessText}>{message}</Text>
        </LinearGradient>
    );


    if (hasPermission === null) {
        return <NoCameraAccess message={"A kamera nem érhető el!"}/>;
    }
    if (Platform.OS !== "web" && hasPermission === false) {
        return <NoCameraAccess message={"Nincs hozzáférésed az eszközhöz!"}/>;
    }

    return (
        <View style={styles.container}>
            <Spinner
                visible={processing}
                textContent={'Feldolgozás...'}
                textStyle={styles.spinnerTextStyle}
            />
            {Platform.OS !== "web" && <BarCodeScanner
                onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                style={[StyleSheet.absoluteFillObject, {flex: 1, height: height}]}
            />}
            {Platform.OS === "web" && <NoCameraAccess message={"Nincs hozzáférésed az eszközhöz!"}/>}
            <View style={styles.overlay}>
                <View style={styles.box}>
                    <TextInput
                        keyboardType="numeric"
                        style={styles.input}
                        placeholder="Adja meg vagy olvassa be a jegy azonosítóját"
                        value={ticketCode}
                        onChangeText={setTicketCode}
                    />
                    <TouchableOpacity style={styles.button} onPress={handleSend}>
                        <Icon name="arrow-right" size={24} color="white" />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    lottie: {
        width: 200,
        height: 200,
        marginBottom: -40
    },
    noAccessText: {
        marginTop: 20,
        fontSize: 20,
        fontWeight: 'bold',
        color: '#FF6347',
        textAlign: 'center',
        textShadowColor: '#000',
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
    },

    overlay: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: 40,
        backgroundColor: 'transparent',
    },
    box: {
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.5,
        elevation: 5,
    },
    input: {
        flex: 1,
        height: 40,
        paddingHorizontal: 10,
        borderRadius: 5,
        backgroundColor: '#f0f0f0',
    },
    button: {
        marginLeft: 10,
        padding: 10,
        backgroundColor: 'orange',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },

    message: {
        textAlign: 'center',
        paddingBottom: 10,
    },
    camera: {
        flex: 1,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        margin: 64,
    },
    text: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
    },
});

export default TicketEntryScreen;
