// Importing
import {scale} from "react-native-size-matters";
import {getStatusBarHeight} from "react-native-status-bar-height";
import Toast from "react-native-toast-message";

// Function to display Toast alerts like success, error and info
const ToastAlert = (type, position, text1, text2, visibilityTime) =>
    Toast.show({
        type: type,
        position: position,
        text1: text1,
        text2: text2,
        visibilityTime: visibilityTime,
        topOffset: scale(getStatusBarHeight() + 10),
        bottomOffset: scale(40),
            text1Style: {
                fontSize: scale(16),
                fontWeight: 'bold'
            },
            text2Style: {
                fontSize: scale(13)
            }
    });

// Exporting
export default ToastAlert;
