import React, {useState} from "react";
import {Image, Platform, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {TextInput} from "react-native-paper";
import {SharedElement} from 'react-navigation-shared-element';
import {emailValidator} from "../helpers/emailValidator";
import {passwordValidator} from "../helpers/passwordValidator";
import {theme} from "../core/theme";
const LogoImage = require("../../assets/icon.png")

import axios from "axios";
import {Toast_Alert_Position, Toast_Alert_Visibility_Time} from "../../index";
import * as Device from 'expo-device';
import ToastAlert from "../helpers/ToastAlert";
import {ApiConfig} from "../config/ApiConfig";
import Spinner from "react-native-loading-spinner-overlay";
import {LinearGradient} from "expo-linear-gradient";
import {getAccessToken, setAccessToken, setUserInfo} from "../helpers/storage";
import {useNavigation} from "@react-navigation/native";
import {Popup, Root} from "react-native-popup-confirm-toast";
import AsyncStorage from "@react-native-async-storage/async-storage";

const LoginScreen = (props) => {
    const [email, setEmail] = useState({value: "", error: ""});
    const [password, setPassword] = useState({value: "", error: ""});
    const [wrong, setWrong] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState({});

    const navigation = useNavigation();

    React.useEffect(() => {
        const checkLogin = async () => {
            if (await getAccessToken()) {
                navigation.navigate("HomeScreen");
            }
        }
        checkLogin();
    }, [navigation]);

    React.useEffect(() => {
        const getDeviceInfo = async () => {
            const deviceName = await Device.deviceName;
            const deviceId = Device.osBuildId;
            const systemVersion = Device.osVersion;
            setDeviceInfo({deviceName, deviceId, systemVersion});
        };

        getDeviceInfo();
    }, []);

    const _loginAction = async () => {
        //Keyboard.dismiss();

        setLoading(true);

        if (!email) {
            setWrong()
            return ToastAlert(
                "error",
                Toast_Alert_Position,
                "Bejelentkezés",
                "E-mail cīm megadása kötelezō!",
                Toast_Alert_Visibility_Time
            );
        }
        if (!password.value) {
            return ToastAlert(
                "error",
                Toast_Alert_Position,
                "Bejelentkezés",
                "Jelszó megadása kötelezō!",
                Toast_Alert_Visibility_Time
            );
        }

        if (!email.value || !password.value) return false;
        if (email.error || password.error) return false;

        axios.post(ApiConfig.apiURL + "/auth/local", {
            email: email.value,
            password: password.value,
            headers: {
                "Accept-Language": "hu_HU",
                "x-device-id": deviceInfo.deviceId,
                "x-device-name":
                    deviceInfo.deviceName +
                    " / " +
                    deviceInfo.systemVersion,
            },
        }).then(async function ({data}) {

            if (data.token && data.user._id) {
                setLoading(false);
                const bodyComponent = ({altProps, bodyProps}) => {
                    return (
                        <View
                            style={{display: "flex", flexDirection: "column"}}
                            onLayout={(e) => {
                                if (bodyProps.onLayout) bodyProps.onLayout(e);
                            }}
                        >
                            {data.user.restaurants[0]?.places &&
                                [...data.user.restaurants[0]?.places].map((place) => (
                                    <View style={{marginTop: 15}}>
                                        <TouchableOpacity
                                            style={{
                                                borderColor: "#505050",
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                padding: 10,
                                                marginTop: 5,
                                            }}
                                            onPress={async () => {

                                                await setUserInfo(data.user);
                                                await setAccessToken(data.token);

                                                AsyncStorage.setItem("lastUser", JSON.stringify(data.user));
                                                AsyncStorage.setItem(
                                                    "restaurant",
                                                    JSON.stringify(data.user.restaurants[0])
                                                );
                                                AsyncStorage.setItem(
                                                    "restaurantPlace",
                                                    JSON.stringify(place)
                                                );


                                                ToastAlert(
                                                    "success",
                                                    Toast_Alert_Position,
                                                    "Bejelentkezés",
                                                    "Sikeres bejelentkezés!",
                                                    Toast_Alert_Visibility_Time
                                                );

                                                return navigation.navigate("HomeScreen");
                                            }}
                                            activeOpacity={0.6}
                                        >
                                            <Text style={{color: "black"}}>
                                                {place.name || place.raw}
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                ))}
                        </View>
                    );
                };


                /* Popup.show({
                        type: "confirm",
                        useNativeDriver: true,
                        label: props.restaurantPlace?._id
                            ? "Üzlet mentése és továbblépés"
                            : "Bezárás mentés nélkül",
                        textBody: "Válassz üzletet a továbblépéshez:",
                        //bodyComponentForce: true,
                        bodyComponent: (bodyProps) =>
                            bodyComponent({...props, bodyProps, Popup}),
                        iconEnabled: true,
                        buttonEnabled: false,
                    }); */

                    await setUserInfo(data.user);
                    await setAccessToken(data.token);

                    AsyncStorage.setItem("lastUser", JSON.stringify(data.user));
                    AsyncStorage.setItem(
                        "restaurant",
                        JSON.stringify(data.user.restaurants[0])
                    );
                    AsyncStorage.setItem(
                        "restaurantPlace",
                        JSON.stringify(data.user.restaurants[0].places[0])
                    );

                    ToastAlert(
                        "success",
                        Toast_Alert_Position,
                        "Bejelentkezés",
                        "Sikeres bejelentkezés!",
                        Toast_Alert_Visibility_Time
                    );

                    return navigation.navigate("HomeScreen");

            } else {
                setLoading(false);

                return ToastAlert(
                    "error",
                    Toast_Alert_Position,
                    "Bejelentkezés",
                    "Hibás email cím vagy jelszó! ",
                    Toast_Alert_Visibility_Time
                );
            }
        }).catch(function (error) {
            setLoading(false);
            return ToastAlert(
                "error",
                Toast_Alert_Position,
                "Bejelentkezés",
                "Hibás email cím vagy jelszó! ",
                Toast_Alert_Visibility_Time
            );
        });

    };


    const onLoginPressed = () => {
        const emailError = emailValidator(email.value);
        const passwordError = passwordValidator(password.value);
        if (emailError || passwordError) {
            setEmail({...email, error: emailError});
            setPassword({...password, error: passwordError});
            return;
        }
        return _loginAction();

    };

    return (
        <LinearGradient
            // Replace colors with the ones matching the image you provided
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}  // Example gradient colors
            style={styles.background}
        >
            <Root>
                <View style={styles.container}>
                    <Spinner
                        visible={loading}
                        textContent={'Bejelentkezés folyamatban...'}
                        textStyle={styles.spinnerTextStyle}
                    />
                    <SharedElement id="logo">
                        {Platform.OS !== "web" && <Image
                            source={LogoImage}
                            style={[styles.logo, { width: 150, height: 150 }]}
                        />}
                        {Platform.OS === "web" && <img
                            alt={"Happy Solutions Logo"}
                            src={'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png'}
                            style={styles.logo}
                        />}
                    </SharedElement>
                    <TextInput
                        label="E-mail cím"
                        returnKeyType="next"
                        value={email.value}
                        onChangeText={(text) => setEmail({value: text, error: ""})}
                        error={!!email.error}
                        errorText={email.error}
                        autoCapitalize="none"
                        autoCompleteType="email"
                        textContentType="emailAddress"
                        keyboardType="email-address"
                        style={styles.input}
                    />
                    <TextInput
                        label="Jelszó vagy PIN kód"
                        returnKeyType="done"
                        value={password.value}
                        onChangeText={(text) => setPassword({value: text, error: ""})}
                        error={!!password.error}
                        errorText={password.error}
                        secureTextEntry
                        style={styles.input}
                    />
                    {false && <View style={styles.forgotPassword}>
                        <TouchableOpacity onPress={() => navigation.navigate("ResetPasswordScreen")}>
                            <Text style={styles.forgot}>Forgot your password?</Text>
                        </TouchableOpacity>
                    </View>}
                    <TouchableOpacity style={styles.loginButton} onPress={onLoginPressed}>
                        <Text style={styles.loginButtonText}>Bejelentkezés</Text>
                    </TouchableOpacity>
                    {false && <View style={styles.row}>
                        <Text>You do not have an account yet?</Text>
                        <TouchableOpacity onPress={() => navigation.replace("RegisterScreen")}>
                            <Text style={styles.link}>Create!</Text>
                        </TouchableOpacity>
                    </View>}
                </View>
            </Root>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        width: '100%',
    },
    logo: {
        width: 150,
        height: 150,
        marginBottom: 120,
    },
    input: {
        width: '100%',
        marginBottom: 10
    },
    forgotPassword: {
        width: "100%",
        alignItems: "flex-end",
        marginBottom: 10,
    },
    forgot: {
        fontSize: 13,
        color: theme.colors.secondary,
    },
    loginButton: {
        backgroundColor: "#F1B34B",
        paddingVertical: 15,

        width: '100%',
        borderRadius: 5,
        marginTop: 20,
    },
    loginButtonText: {
        fontSize: 18,
        color: "white",
        textAlign: 'center',
    },
    row: {
        flexDirection: "row",
        marginTop: 4,
    },
    link: {
        fontWeight: "bold",
        color: theme.colors.primary,
        marginLeft: 5,
    },
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    spinnerTextStyle: {
        color: '#FFF'
    },
});

LoginScreen.sharedElements = () => {
    return [{id: 'logo'}];
};

export default LoginScreen;
