import React, { createContext, useState } from 'react';

// Create a Context for the cart
export const CartContext = createContext();

// Create a provider component
export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    const addToCart = (item) => {
        setCartItems([...cartItems, item]);
    };

    const setCart = (newCartItems) => {
        setCartItems(newCartItems);
    };

    const removeFromCart = (itemId) => {
        setCartItems(cartItems.filter(item => item._id !== itemId));
    };

    const cartTotal = cartItems.reduce((total, item) => total + (item.price * (item.quantity || 1)), 0);

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, setCart, cartTotal }}>
            {children}
        </CartContext.Provider>
    );
};